<template>
	<NuxtLink :href="{ name: 'index' }" class="lg:mr-6">
		<nuxt-img
			src="/images/logo.png"
			alt="Vispetta Logo"
			height="40"
			width="187"
			format="webp"
			quality="80"
			priority
		/>
	</NuxtLink>
</template>
